













































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    }),
    titleList() {
      return [
        this.$t("cyberswitch.shortTitle01"),
        this.$t("cyberswitch.shortTitle02"),
        this.$t("cyberswitch.shortTitle03"),
        this.$t("cyberswitch.shortTitle04"),
        this.$t("cyberswitch.shortTitle05"),
        this.$t("cyberswitch.shortTitle06"),
        this.$t("cyberswitch.shortTitle07"),
        this.$t("cyberswitch.shortTitle09"),
        this.$t("cyberswitch.shortTitle10"),
        // this.$t("cyberswitch.shortTitle08"),
        this.$t("joinUs"),
        "FOOTER"
      ];
    }
  }
});
